$primary: #4580A4;

@import "~bootstrap/scss/bootstrap";

.btn-outline-primary:hover,
.btn-primary,
.btn-primary:hover{
  color: #fff;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

#navbarHeader {
    transition: .5s margin-top ease-in-out;

    &.visible {
        margin-top: 0px;
    }

    &:not(.visible) {
        margin-top: -196px;
    }
}

.about-picture {
    background: url('/images/june.png') no-repeat;
    background-size: contain;
    height: 30rem;
}

.list-group-item {
    &.active {
        a {
            color: #FCFCFC;
        }
    }
}

a.nav-link {
    color: rgba(255, 255, 255, 0.5);

    &:hover {
        color: rgba(255, 255, 255, 0.75);
    }
}

.cursor-pointer{
    cursor: pointer;
}

.text-align-center {
    text-align: center;
}

.home {
  background: url("/images/home-bg-ocean.jpg") 0px -50px fixed no-repeat;
  background-size: cover;

  .home-section {
    height: 600px;
  }

  .main-headline {
    top: 25%;
    left: 20%;
  }
}

.weekday-headline {
  background-color: #4580A4;
  color: #fff;
}

.class-image {
  width: 100px;
  height: 100px;
  background-size: cover;
}

.home-image {
  max-width: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #f8f9fa;
}